export class Usuario {
  constructor({ id, nomeUsuario, nome, nomeEmpresa, cnpj, email, telefone, senha, confirmacaoSenha, perfisId, celular, comercial, codigoAgente }) {
    this.id = id;
    this.nomeUsuario = nomeUsuario;
    this.nomeEmpresa = nomeEmpresa;
    this.nome = nome;
    this.cnpj = cnpj;
    this.email = email;
    this.telefone = telefone;
    this.celular = celular;
    this.senha = senha;
    this.confirmacaoSenha = confirmacaoSenha;
    this.perfisId = perfisId;
    this.comercial = comercial;
    this.codigoAgente = codigoAgente;
  }
}
