<template>
  <div>
    <b-card class="mb-4">
      <div class="card mb-4">
        <div class="d-flex align-items-start">
          <div>
            <h4 class="card-title mb-1">
              {{ $t("USUARIOS.DADOS_PESSOAIS") }}
            </h4>
          </div>
        </div>
      </div>
      <div class="res-inputs">
        <input-text
          class="user-input"
          disabled
          ref="nomeUsuario"
          v-model="form.nomeUsuario"
          :label="$t('GERAL.USUARIO')"
          required
        />
        <input-text
          class="user-input"
          ref="nomeUsuario"
          v-model="form.nome"
          :label="$t('USUARIOS.NOME')"
          required
        />

        <input-text
          class="user-input"
          ref="nomeEmpresa"
          v-model="form.nomeEmpresa"
          :label="$t('USUARIOS.NOME_EMPRESA')"
          required
        />

        <input-mask
          class="user-input"
          disabled
          ref="cnpj"
          v-model="form.cnpj"
          :label="$t('USUARIOS.CNPJ')"
          :mask="['###.###.###-##', '##.###.###/####-##']"
          required
        />

        <input-text
          class="user-input"
          type="email"
          ref="email"
          v-model="form.email"
          :label="$t('USUARIOS.EMAIL')"
          required
        />

        <input-mask
          class="user-input"
          ref="telefone"
          v-model="form.telefone"
          :label="$t('USUARIOS.TELEFONE')"
          :mask="['(##) ####-####', '(##) #####-####']"
          required
        />

        <input-mask
          class="user-input"
          ref="celular"
          v-model="form.celular"
          :label="$t('USUARIOS.CELULAR')"
          :mask="['(##) ####-####', '(##) #####-####']"
          required
        />
      </div>

      <div>
        <div class="card mb-4 mt-4">
          <div class="d-flex align-items-start">
            <div>
              <h4 class="card-title mb-1">
                {{ $t("USUARIOS.DADOS_ACESSO") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="res-inputs">
          <div>
            <input-text
              ref="senha"
              v-model="form.senha"
              type="password"
              :label="$t('GERAL.SENHA')"
              :minLength="6"
              required
            />
            <verifica-forca-senha embaixo :value="forcaSenha"></verifica-forca-senha>

          </div>

          <input-text
            ref="confirmacaoSenha"
            v-model="form.confirmacaoSenha"
            type="password"
            :label="$t('GERAL.CONFIRMACAO_SENHA')"
            :minLength="6"
            :validate="validarSenha"
            required
          />


          <b-button
            variant="primary"
            class="btn-nova-senha"
            @click="alterarSenha"
            >{{ $t("GERAL.TROCAR_SENHA") }}</b-button
          >
        </div>
      </div>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button variant="primary" @click="salvar">{{
            $t("GERAL.SALVAR")
          }}</b-button>
        </b-col>
      </b-row>
    </b-card>

    {{ urlEnv }}
  </div>
</template>

<script>
import UsuarioService from "@/common/services/usuario/usuario.service";
import MinhaContaService from "@/common/services/usuario/minha-conta.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { Usuario } from "@/common/models/Usuario";
import InputText from "@/components/inputs/InputText";
import InputMask from "@/components/inputs/InputMask";
import helpers from "@/common/utils/helpers";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import Swal from "sweetalert2";
import VerificaForcaSenha from "./components/VerificaForcaSenha.vue";
export default {
  name: "Formulario",
  components: {
    InputText,
    InputMask,
    VerificaForcaSenha,
  },
  data() {
    return {
      form: new Usuario({}),
      perfis: [],
      perfisIds: [],
      urlEnv: "",
      perfilSelecionado: "",
      perfisSelecionados: [],
      perfisDeletados: [],
      forcaSenha: "Vazio",
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("GERAL.MINHA_CONTA") },
    ]);
    this.buscar();
  },
  computed: {
    validarCpfCnpj() {
      return helpers.validarCpfCnpj(this.form.cnpj);
    },
    validarCep() {
      return helpers.validarCep(this.formEndereco.cep);
    },
    validarSenha() {
      return this.form.senha == this.form.confirmacaoSenha;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "form.senha"(value) {
      this.verificarForcaDaSenha();
    },
  },
  methods: {
    verificarForcaDaSenha() {
      let senha = this.form.senha;
      UsuarioService.verificarForcaDaSenha(senha)
        .then((result) => {
          this.forcaSenha = result.data.forcaSenhaEnum;
        })
        .catch((err) => console.log(err));
    },
    validarFormulario(validaSenha) {
      let arValidation = [];
      arValidation.push(this.$refs.nomeUsuario.valid());
      arValidation.push(this.$refs.nomeEmpresa.valid());
      arValidation.push(this.$refs.cnpj.valid());
      arValidation.push(this.$refs.email.valid());
      arValidation.push(this.$refs.telefone.valid());
      arValidation.push(this.$refs.celular.valid());
      if (validaSenha) {
        arValidation.push(this.$refs.senha.valid());
        arValidation.push(this.$refs.confirmacaoSenha.valid());
      }
      return arValidation.filter((elem) => elem == false).length == 0;
    },
    alterarSenha() {
      if (!this.validarFormulario(true)) return;
      this.$store.dispatch(START_LOADING);

      MinhaContaService.alterarSenha(this.form).then(() => {
          Swal.fire({
            title: this.$t("GERAL.SUCCESSO"),
            text: this.$t("GERAL.SENHA_ALTERADA"),
            icon: "success",
            confirmButtonText: this.$t("GERAL.OK"),
          });
      }).finally(() => {
        this.$store.dispatch(STOP_LOADING);
      });
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      this.form.endereco = this.formEndereco;

      MinhaContaService.salvar(this.form)
        .then(() => {
          Swal.fire({
            title: this.$t("GERAL.SUCCESSO"),
            text: this.$t("USUARIOS.MENSAGEM_CADASTRADO"),
            icon: "success",
            confirmButtonText: this.$t("GERAL.OK"),
          });
        })
        .catch((err) => {
          Swal.fire({
            title: this.$t("GERAL.ERRO"),
            text: err.response.data.errors,
            icon: "error",
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscar() {
      this.$store.dispatch(START_LOADING);

      MinhaContaService.buscar()
        .then((result) => {
          this.form = new Usuario({ ...result.data.data });

          let perfis = this.perfis;
          let perfisId = result.data.data.perfisId;

          for (let i = 0; i < perfisId.length; i++) {
            for (let b = 0; b < perfis.length; b++) {
              if (perfisId[i] == perfis[b].id) {
                perfis[b].active = true;
                perfis[b].inBase = true;
              }
            }
          }

          this.perfis = perfis;
        })
        .catch((err) => {
          Swal.fire({
            title: this.$t("GERAL.ERRO"),
            text: err.response.data.errors,
            icon: "error",
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
